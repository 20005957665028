/******** HOME PAGE *********/
#torneria-bernio{

  #paragrafo1{
    position: relative;
    margin-top: 60px;
    text-align: center;
    // @media #{$underxl} {
    //   margin-top: 20px;
    // }
    // @media #{$under-desktop-xl} {
    //   margin-top: 200px;
    // }
    // @media #{$small-phone} {
    //   margin-top: 0px;
    // }
    h3{
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 25px;
      @media #{$desktop-xl} {
        font-size: 40px;
        margin-bottom: 25px;
      }
      @media #{$under-desktop-xl} {
        font-size: 40px;
      }
      @media #{$underdesktop} {
        font-size: 40px;
      }
    }

    span{
      color: $orange;
    }
  }
  #paragrafo2{
    position: relative;
    margin-top: 60px;
    text-align: center;
    @media #{$undermd} {
      margin-top: 80px;
    }
    span{
      color: $orange;
    }
  }

  .box-category-home{
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    @media #{$undertablet} {
      margin-bottom: 40px;
    }

    figure {
      overflow: hidden;
      border-bottom: 4px solid $orange;
      z-index: 1;
      position: relative;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      @media #{$undermd} {
        margin: 20px !important;
      }
      img{
        width: 100%;
      }
      figcaption{
        width: 100%;
        bottom: 0;
        position: absolute;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        .triangolo{
          content: '';
           background: url("../images/triangolo.svg") no-repeat;
           width: 102%;
           left: -2px;
           height: 90px;
           top: 2px;

           /* background-size: 100% 100%; */
           display: block;
           background-position: center bottom;
           bottom: 0px;
           position: relative;
          // content: url("../images/triangolo.svg");
          // position: relative;
          // width: 100%;
          // height: auto;
          // left: 0;
          // bottom: 0;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
             top:20px;
           }

           @supports (-ms-ime-align:auto) {
             top:20px;
           }

        }

        .elementi{
          padding-top:20px;
          background: $white;
          -webkit-transition: all 0.5s ease-in;
          -moz-transition: all 0.5s ease-in;
          -o-transition: all 0.5s ease-in;
          transition: all 0.5s ease-in;
        }

        .titolo{
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          position: relative;
          top:-20px;
          @media #{$underxl} {
            padding-top: 10px;
          }
          @media #{$undermd} {
            padding-top: 0px;
          }
        }

        .descrizione{
          opacity: 0;
          -webkit-transition: opacity 1.0s ease-in-out;
          -moz-transition: opacity 1.0s ease-in-out;
          -ms-transition: opacity 1.0s ease-in-out;
          -o-transition: opacity 1.0s ease-in-out;
          transition: opacity 1.0s ease-in-out;
          -webkit-transform: translate3d(0,0px,0);
          transform: translate3d(0,0px,0);
          color: $black;
          bottom: -40px;
          padding: 0px 30px;
          top:30px;
          text-align: center;
          height: 0;

        }
        .link{
          height: 0;
          opacity: 0;
          background: $white;
          position: relative;
          color: $orange;
          height: auto;
          border:3px solid $white;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 600;
          opacity: 0;
          border:2px solid $orange;
          padding: 5px 20px;
          @include border-radius(0);
          margin: 15px 0;
          -webkit-transition: opacity .5s ease-in-out;
          -moz-transition: opacity .5s ease-in-out;
          -ms-transition: opacity .5s ease-in-out;
          -o-transition: opacity .5s ease-in-out;
          transition: opacity .5s ease-in-out;
        }

        h3 {
          position: relative;
          left: 0;
          width: 100%;
          color: $black;
          font-weight: 800;
          font-size: 25px;
          padding-bottom: 25px;
          margin: 0;
          text-transform: uppercase;
          -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
          transition: opacity 0.5s, transform 0.5s;
          a{
            color: $black;
            font-weight: 800;
            text-decoration: none;
            font-size: 25px;
            @media #{$under-desktop-xl} {
              font-size: 24px;
            }
            @media #{$small-phone} {
              font-size: 22px;
            }
            @media #{$xsmall-phone} {
              font-size: 20px;
            }
          }

        }
      }
    }

    figure:hover {
      overflow: hidden;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      figcaption{
        position: absolute;
        height: auto;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
      }

      h3 {
      	color:$black;
      	-webkit-transform: translate3d(0,0px,0) translate3d(0,0px,0);
      	transform: translate3d(0,0px,0) translate3d(0,0px,0);
        -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
        transition: opacity 0.5s, transform 0.5s;
        padding-bottom: 0;
      }
      .triangolo{
        background: url("../images/triangolo_hover.svg") no-repeat;
        width: 102%;
        left: -2px;
        height: 90px;
        /* background-size: 100% 100%; */
        display: block;
        background-position: center bottom;
        @media #{$undermd} {
          top:1px;
        }
      }

      .elementi{
        background: $white;
        -webkit-transition: all 0.1s ease-in;
        -moz-transition: all 0.1s ease-in;
        -o-transition: all 0.1s ease-in;
        transition: all 0.1s ease-in;
      }

      .titolo{
        top:-20px;
        position: relative;
        @media #{$underxl} {
          padding-top: 10px;
        }
        @media #{$undermd} {
          padding-top: 0px;
        }
      }

      .descrizione {
      	opacity: 1;
        display: block;
        height: 100%;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        -webkit-transform: translate3d(0,0px,0);
        transform: translate3d(0,0px,0);
        color: $black;
        bottom: -40px;
        padding: 0px 30px;
        top:30px;
        text-align: center;
        @media #{$underxl} {
          top: 40px;
        }
        @media #{$under-desktop-xl} {
          top:50px;
        }
        @media #{$underdesktop} {
          top:50px;
        }

        ul {
           list-style: none;
           padding:0;
           margin:0;
           margin-left: 30px;
           top: -10px;
           position: relative;
        }

        li::before {
          content: "•";
          color: $orange;
          display: inline-block;
          width: 0.5em;
          margin-left: -1em;
          font-size: 50px;
          line-height: 10px;
          left: 0px;
          position: relative;
          right: -50px;
          top: 7px;
        }
      }

      .link{
        display: inline-block;
        background: $white;
        position: relative;
        color: $orange;
        height: auto;
        border:3px solid $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        opacity: 1;
        border:2px solid $orange;
        padding: 5px 20px;
        @include border-radius(0);
        margin: 15px 0;
        -webkit-transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -ms-transition: opacity .5s ease-in-out;
        -o-transition: opacity .5s ease-in-out;
        transition: opacity .5s ease-in-out;
      }

    }

    figure::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 1;
      // background: -webkit-linear-gradient(top, rgba(19,19,19,0) 0%, rgba(19,19,19,0.9) 100%);
      // background: linear-gradient(to bottom, rgba(19,19,19,0) 0%, rgba(19,19,19,0.9) 100%);
      //background: transparent;
      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;

    }

    figure:hover::before {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
      content: '';
    	opacity: 1;
    	// background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
    	// background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
      background: transparent;
      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;
    }
  }

  #gallery{
    text-align: center;
    @media #{$undertablet} {
      margin: 0 40px;
    }
    .cols{
      padding: 0;


      figure{
        position: relative;
      }
      figcaption{
        display: none;

        a{
          color: $white;
        }
      }
    }

    .gallery-img{
      margin-bottom: 60px;

      img{
        position: relative;
        z-index: 1;
      }

      .cols:hover{
        background: rgba(227,138,62,0.7);

        figcaption{
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 999;
          @include transform(translate(-50%, -50%));
          .desc{
            color: $white;
          }

          i{
            padding: 8px 10px;
            border: 1px solid $white;
          }

        }
        img{
          position: relative;
          z-index: -1;
        }
      }
    }

    .cta-button-arrow{
      position: relative;
      display: inline-block;
      top:0;
    }
    .cta-button-arrow:hover{
      position: relative;
      display: inline-block;
      top:0;
      margin-top: 0;
    }
  }
}
