footer{
  padding: 20px 0;
  color: $black;
  font-size: 16px;
  background: $grigioScuro;
  position: relative;
  text-align: left;
  float: left;
  width: 100%;
  clear: both;

  .logo{
    max-width: 300px;
    margin-left: 0px !important;
    height: 90px;
    margin-bottom: 0px !important;
    background: transparent ;
    padding: 0px 10px;
    width: 280px;
    height: 0;
    border-bottom: 80px solid $white;
    border-right: 30px solid transparent;
    position: relative;
    top:10px;
    left: -15px;
    position: relative;
    @media #{$underdesktop} {
      border-bottom: 75px solid #fff;
      width: 250px;
    }
    @media #{$undermd} {
      top:-2px;
    }
    @media #{$undertablet} {
      top: -2px;
      margin-bottom: 20px !important;
      position: relative;
      left: 0;
    }
  }

  .bordered{
    border-bottom:1px solid $white;
    position: relative;
    @media #{$undermd} {
      padding: 0 60px;
    }
  }
  .bordered2{
    border-bottom:1px solid $white;
    position: relative;
    @media #{$undermd} {
      padding: 0 60px;
      padding-bottom: 20px;
    }
  }

  .col-box{
    position: absolute;
    bottom: 0;
    @media #{$undermd} {
      position: relative;
    }
  }
  .box-row{
    position: relative;
    width: 100%;
    bottom: 10px;
    left: 52%;
    @media #{$undermd} {
      top:20px;
      left: 40px;
    }
    @media #{$undertablet} {
      left: 0px;
    }
  }

  .lista{
    margin-top: 25px;
    @media #{$undermd} {
      margin-top: 5px;
      margin-bottom: 20px;
    }
    ul {
       list-style: none;
       padding:0;
       margin:0;
       margin-left: 20px;
       padding-left: 30px;
       @media #{$underdesktop} {
         margin-left: 20px;
       }

    }

    li::before {
      content: "•";
      color: $orange;
      display: inline-block;
      width: 0.5em;
      margin-left: -1em;
      font-size: 50px;
      line-height: 10px;
      left: 0px;
      position: relative;
      right: -50px;
      top: 7px;
    }

  }

  .indicazioni{
    margin-top: 25px;
    padding-top: 20px;
    @media #{$undermd} {
      margin-top: 0px;
      padding-top: 0px;
    }
    p{
      font-size: 15px;
      line-height: 20px;
      color: $white;
      @media #{$underdesktop} {
        display: inline-flex;
      }

      @media #{$undermd} {
        display: flex;
      }
    }

    i{
      color: $orange;
      margin-right: 10px;
      width: 20px;
    }
  }

  .list{
    color: $white;
  }

  .col-list{
    position: relative;
    bottom: 0;
    margin-bottom: 10px;
  }
  .list-row{
    position: relative;
    width: 100%;
    bottom: 10px;
    left: 0%;
  }

  a{
    color: $white;
    font-weight: 400;
    text-decoration: none;
    font-size: 15px;
  }
  a:hover{
    color: $orange;
    font-weight: 400;
    font-size: 15px;
  }

  .titolo{
    color: $white;
    font-weight: 400;
    text-align: left;

    a{
      font-weight: 400;
    }
  }
}

.copyright{
  color: $white;
  padding: 0px 0 0;
  font-size: 8px;
  background: transparent;
  text-align: center;
  @media #{$undermd} {
    margin-top: 15px;
  }

  small{
    font-size: 12px;
    letter-spacing: 1px;
  }

  a{
    color: $white;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
  }
  a:hover{
    font-size: 12px;
    font-weight: 400;
    color: $orange;
  }
}

// footer:before{
//   background: url("../images/sfondo_sito.png") no-repeat;
//   content: "";
//   background-size: 50%;
//   position: absolute;
//   left: 50%;
//   height: 50px;
//   width: 50px;
//   top: -60px;
//   margin: 0 12px;
//   @include transform(translateX(-50%));
// }
