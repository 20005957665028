.cta-page{
  background: $grigioScuro;
  margin-bottom: 20px;

  @media #{$undermd} {
    margin-top: 40px;
  }

  .descrizione-box{
    position: relative;
    @media #{$underdesktop} {
      padding: 20px;
    }
    @media #{$undertablet} {
      position: relative;
      @include transform(translateY(0%));
      top:0;
      padding: 40px;
      text-align: center;
    }
  }
  .img-sx{
    figure{
      width: 100%;
      height: 200px;
      overflow:hidden;
      max-width: inherit;
      @media #{$undertablet} {
        height: auto;
      }
    }

    img {
      position: absolute;
       left: 0;
       top: 50%;
       -webkit-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);
       margin-left: 0px;
       height: 100%;
       @media #{$under-desktop-xl} {
         margin-left: -130px;
       }
       @media #{$undermd} {
         margin-left: -160px;
       }
       @media #{$undertablet} {
         display: none;
       }
    }
  }

  .titolo{
    color: $white;
    font-size: 40px;
    text-align: left;
    margin-right: 200px;
    position: relative;
    @media #{$under-desktop-xl} {
      margin-right: 250px;
    }
    @media #{$underdesktop} {
      margin-right: 280px;
    }
    @media #{$undermd} {
      margin-right: 60px;
    }
    @media #{$undertablet} {
      text-align: center;
      margin-right: 0;
    }
  }

  .descrizione{
    color: $white;
    margin-right: 200px;
    @media #{$undermd} {
      margin-right: 60px;
    }
    @media #{$undertablet} {
      margin-right: 0;
    }

    p{
      margin-top: 5px;
      line-height: 20px;
    }

  }
}

#tornitura{
  .img-sx{
    figure{
      width: 100%;
      height: 200px;
      overflow:hidden;
      max-width: inherit;
      @media #{$undertablet} {
        height: auto;
      }
    }

    img {
      position: absolute;
       left: 50%;
       top: 50%;
       -webkit-transform: translate(-50%,-50%);
       -ms-transform: translate(-50%,-50%);
       transform: translate(-50%,-50%);
       margin-left: 0px;
       height: 100%;
       @media #{$underxl} {
         margin-left: -100px;
       }
       @media #{$under-desktop-xl} {
         margin-left: -220px;
       }
       @media #{$undermd} {
         margin-left: -160px;
       }
       @media #{$undertablet} {
         display: none;
       }
    }
  }
}
