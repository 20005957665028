#paragrafo{
  position: relative;

  .descrizione{
    text-align: center;
    line-height: 25px;
  }

}

#qualita{

  h3{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
    @media #{$desktop-xl} {
      font-size: 40px;
      margin-bottom: 25px;
    }
    @media #{$under-desktop-xl} {
      font-size: 40px;
    }
    @media #{$underdesktop} {
      font-size: 40px;
    }
    @media #{$small-phone} {
      font-size: 30px;
    }
  }
}

#settori{
  .title{
    @media #{$undertablet} {
      text-align: center;
    }
  }
  .contenuti{
    @media #{$undertablet} {
      text-align: center;
    }
  }
}

#elenco_settori{
  padding: 40px 0;
  background: $grey;

  ul {
    list-style-type: none;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin: 0 auto;
    width: 85%;
    @media #{$undertablet} {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

    @media #{$underdesktop} {
      width: 100%;
    }

    li {
      border-left: 5px solid $orange;
      padding-left: 10px;
      list-style: none;
      background-size: 35px;
      line-height: 30px;
      font-weight: 800;
    }
  }
}
