/******** HOME PAGE *********/
#content{
  // position: relative;
  // top:-135px;
  // margin-bottom: -135px;
}

#header-home{
  position: relative;
  margin-bottom: 150px;
  height: 100vh;
  @media #{$undermd} {
    margin-bottom: 200px;
  }
  @media #{$undermd} {
    margin-bottom: 100px;
  }

  .container{
    position: relative;
    height: 100vh;
  }
  figure {
    position: relative;
    width: 100%;
    height: 100vh;
    top:-100px;

    .img-cover {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      z-index: -10;
      opacity: 1;

    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        .img-cover {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

     @supports (-ms-ime-align:auto) {
       /* IE Edge 12+ CSS styles go here */
        .img-cover {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

    figcaption {
      position: absolute;
      top: 45%;
      width: 100%;
      @include transform(translate(-50%,-50%));
      color: $white;
      left: 50%;
      text-align: center;
      height: 100%;
      @media #{$undertablet} {
        width: 100% !important;
      }


      .immagine{
        height: auto;
        width:auto;
        position: absolute;
        bottom:-45px;
        object-fit: none;
        bottom: -280px;
        left: 47%;
        @media #{$under-super-desktop} {
          left: 45%;
        }
        @media #{$under-desktop-xl} {
          bottom: -220px;
        }
        @media #{$desktop} {
          bottom: -200px;
        }
        @media #{$undermd} {
          left:50%;
          @include transform(translateX(-50%));
          bottom: -170px;
        }
        @media #{$small-phone} {
          bottom: -100px;
        }
        img{
          width: 100%;
        }
      }


      .pagina{
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        padding: 20px;
        @media #{$small-phone} {
          top: 40%;
        }
      }

      .titolo{
        display: inline-block;
        position: relative;
        text-align: left;
        @media #{$undermd} {
          text-align: center;
        }

        h1{
          border-bottom: 3px solid $orange;
          padding-bottom: 10px;
          margin-bottom: 20px;
          width: 103.8%;
          // @media #{$under-xx-desktop} {
          //   width: 102.8%;
          // }
          @media #{$under-super-desktop} {
            width: 102.2%;
          }
          @media #{$desktop} {
            width: 102.8%;
          }
          @media #{$small-phone} {
            font-size: 25px;
          }

        }
        .titolo_header{
          color: $white;
          font-weight: 700;
          font-size: 60px;
          text-transform: uppercase;
          @media #{$desktop-xl} {
            font-size: 50px;
          }
          @media #{$underdesktop} {
            font-size: 50px;
          }
          @media #{$small-phone} {
            font-size: 35px;
            padding: 20px;
          }
          @media #{$xsmall-phone} {
            font-size: 25px;
            padding: 0px;
          }

          .titolo_sopra{
            font-size: 40px;
            color: $white;
            @media #{$desktop-xl} {
              font-size: 35px;
            }
            @media #{$under-desktop-xl} {
              font-size: 35px;
            }
            @media #{$underdesktop} {
              font-size: 32px;
            }
            @media #{$small-phone} {
              font-size: 25px;
            }
          }

          span{
            color: $orange;

          }
        }

        .line_orange{
          width: 100%;
          height: 3px;
          background: $orange;
          position: absolute;
          bottom: -5px;
          left: 0;
          @media #{$underdesktop} {
            left: 50%;
            width: 90%;
            @include transform(translateX(-50%));
          }

        }

      }

      .vertical_line_orange{
        width: 3px;
        height: 240%;
        background: $orange;
        position: absolute;
        bottom: 5px;
        left: 50%;
        top: 64%;
        @include transform(translateX(-50%));
        @media #{$under-super-desktop} {
          height: 200%;
        }
        @media #{$underxl} {
          height: 150%;
          left: 49.7%;
          top: 62.5%;
        }
        @media #{$under-desktop-xl} {
          height: 150%;
          top: 63%;
        }
        @media #{$desktop} {
          height: 120%;
          top: 66%;
        }
        @media #{$undermd} {
          display: none;
        }

      }

      strong{
        color: $orange;
        font-weight: 700;
        font-size: 70px;
        text-transform: uppercase;
        @media #{$desktop} {
          font-size: 60px;
        }
        @media #{$undermd} {
          font-size: 60px;
        }
        @media #{$small-phone} {
          font-size: 35px;
        }
        @media #{$xsmall-phone} {
          font-size: 20px;
        }
      }
    }
  }


}

#header-page, #header-post{
  position: relative;

  figure {
    position: relative;
    width: 100%;
    height: 70vh;
    top:-100px;
    margin-bottom: 0px !important;
    img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 70vh;
      z-index: -10;
      opacity: 1;

    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        img {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

     @supports (-ms-ime-align:auto) {
       /* IE Edge 12+ CSS styles go here */
        img {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

    figcaption {
      position: absolute;
      top: 50%;
      width: 100%;
      @include transform(translate(-50%,-50%));
      color: $white;
      left: 50%;
      text-align: center;
      height: 100%;
      @media #{$undertablet} {
        width: 100% !important;
      }

      .pagina{
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        padding: 20px;
        @media #{$undermd} {
          top: 40%;
        }
        @media #{$xsmall-phone} {
          top: 50%;
        }
      }

      .titolo{
        display: inline-block;
        position: relative;

        .titolo_header{
          color: $white;
          font-weight: 700;
          font-size: 60px;
          text-transform: uppercase;
          @media #{$desktop-xl} {
            font-size: 50px;
          }
          @media #{$underdesktop} {
            font-size: 50px;
          }
          @media #{$small-phone} {
            font-size: 35px;
            padding: 20px;
          }
          @media #{$xsmall-phone} {
            font-size: 25px;
            padding: 0px;
          }

          .titolo_sopra{
            font-size: 40px;
            color: $white;
            @media #{$desktop-xl} {
              font-size: 35px;
            }
            @media #{$under-desktop-xl} {
              font-size: 35px;
            }
            @media #{$underdesktop} {
              font-size: 32px;
            }
            @media #{$small-phone} {
              font-size: 25px;
            }
            @media #{$xsmall-phone} {
              font-size: 20px;
            }
          }

          span{
            color: $orange;
          }
        }

        .line_orange{
          width: 100%;
          height: 3px;
          background: $orange;
          position: absolute;
          bottom: -5px;
          left: 0;
          @media #{$underdesktop} {
            left: 50%;
            width: 90%;
            @include transform(translateX(-50%));
          }

        }

      }

      .vertical_line_orange{
        width: 3px;
        height: 260%;
        background: $orange;
        position: absolute;
        bottom: 5px;
        left: 50%;
        top: 102%;
        @include transform(translateX(-50%));
        @media #{$under-super-desktop} {
          height: 240px;
        }
        @media #{$desktop-xl} {
          height: 200px;
        }
        @media #{$under-desktop-xl} {
          height: 180px;
        }
        @media #{$desktop} {
          height: 200px;
        }
        @media #{$undermd} {
          display: none;
        }

      }

      strong{
        color: $orange;
        font-weight: 700;
        font-size: 70px;
        text-transform: uppercase;
      }
    }
  }
}


#complementari{
  figure{
    figcaption{
      .vertical_line_orange {
        @media #{$undermd} {
          height: 250%;
        }
      }
    }
  }
}

#prodotti{
  figure{
    figcaption{
      .vertical_line_orange{
        @media #{$under-desktop-xl} {
          height: 140%;
        }
        @media #{$undertablet} {
          height: 50%;
        }
        @media #{$small-phone} {
          height: 100%;
        }
      }
    }
  }
}
#materiali, #servizi{
  figure{
    figcaption{

      .vertical_line_orange{
        @media #{$small-phone} {
          height: 100%;
        }
      }
    }
  }
}

#contatti{
  figure{
    figcaption{
      .pagina{
        @media #{$under-super-desktop} {
          top: 40%;
        }

        @media #{$under-desktop-xl} {
          top: 40%;
        }
        @media #{$small-phone} {
          top: 30%;
        }
        @media #{$xsmall-phone} {
          top: 40%;
        }
      }

      .vertical_line_orange{
        height: 110%;
        @media #{$under-desktop-xl} {
          height: 80%;
        }
        @media #{$desktop-xl} {
          height: 130%;
        }
        @media #{$undermd} {
          height: 80%;
        }
        @media #{$small-phone} {
          height: 65%;
        }
        @media #{$xsmall-phone} {
          height: 40%;
        }
      }
    }
  }
}


//
//
// #materiali{
//   figure{
//     figcaption{
//       .pagina{
//         @media #{$underdesktop} {
//           top:40%;
//         }
//       }
//
//       .vertical_line_orange{
//
//
//         @media #{$xsmall-phone} {
//           height: 60%;
//         }
//       }
//     }
//   }
// }
