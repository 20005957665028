#contatti{
  position: relative;

  #contatti{
    .contatto{
      text-align: center;

      .titolo{
        font-weight: 800;
        border-bottom: 3px solid $orange;
        display: inline-block;
        padding-bottom: 5px;
      }

      .descrizione{
        margin-top: 20px;
        color: $black;
        text-align: center;
        line-height: 20px;
      }
    }
  }

  #form{
    background: $grey;
    padding: 80px 0;

    .wpcf7{
      color: $orange;
      @media #{$small-phone} {
        padding: 20px!important;
        margin: 0px!important;
      }

      .wpcf7-text, .wpcf7-textarea, .wpcf7-tel {
        width: 100%;
        background: transparent;
        color: $orange !important;
        font-weight: 600;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid transparent;
        border-bottom: 1px solid $black;
        resize: none;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $orange !important;
      }
      ::-moz-placeholder { /* Firefox 19+ */
          color: $orange !important;
      }
      :-ms-input-placeholder { /* IE 10+ */
          color: $orange !important;
      }
      :-moz-placeholder { /* Firefox 18- */
          color: $orange !important;
      }
      .wpcf7 input,
      .wpcf7 textarea{
        color: $orange;
        font-weight: 800;
      }

      .allegato{
        text-align: center;
        cursor: pointer;
        padding-top: 30px;
        top:30px;
        position: relative;
        @media #{$undertablet} {
          margin-bottom: 40px;
        }
        .allega{
          text-align: center;
          cursor: pointer;
        }

        .allega:before{
          content: "";
          background: url("../images/allega_file.svg");
          width: 90px;
          height: 90px;
          position: absolute;
          top: -90px;
          left: 50%;
          @include transform(translateX(-50%));
          margin-top: 40px;
        }
        .file-input{
          display: none;
        }
      }

      .privacy{
        @media #{$undertablet} {
          text-align: center;
        }
      }

      .wpcf7-form{
        margin-bottom: 60px;
      }

      div.wpcf7-response-output{
        margin: 0;
      }

      .submit{
        background: transparent;
        text-align: left;
        display: inline-block;
        color: $orange;
        border:2px solid $orange;
        font-weight: 600;
        border-radius: 0;
        text-decoration: none;
        padding: 5px 50px 5px 50px;
        margin-top: 20px;
        left: 50%;
        position: relative;
        text-transform: uppercase;
        @include transform(translateX(-50%));

      }

      .wpcf7-submit:hover{
        background: transparent;
        text-align: left;
        display: inline-block;
        color: $black;
        border:2px solid $black;
        font-weight: 600;
        border-radius: 0;
        text-decoration: none;
        padding: 5px 50px 5px 50px;
        margin-top: 20px;
        left: 50%;
        position: relative;
        text-transform: uppercase;
        @include transform(translateX(-50%));

      }
    }
  }

  #maps{
    margin-bottom: 0;
    margin-top: 40px;
    .titolo-paragrafo{
      margin-bottom: 40px;
      @media #{$undermd} {
        padding: 0 60px;
      }

    }
    .indicazioni{
      margin-right: -15px;
      padding: 80px;
      background: $black;
      h3{
        font-size: 25px;
        color: $white;
      }
    }
    .accordion-section{
      text-decoration: none;
      a{
        text-decoration: none;
      }
      .titolo-accordion{
        text-decoration: none;
        .icona{
          width: 60px;
          position: relative;
          display: inline;
        }
        .title{
          display: inline;
          color: $white !important;
          font-size: 20px;
          text-transform: none;
          padding-bottom: 10px;
          border-bottom: 1px solid $white;
        }
        .plusminus{
          padding: 2px 6px;
          background: $orange;
          color: $white;
          text-decoration: none;
          margin-left: 10px;
        }
      }

      .contenuti{
        color: $white;
        ul {
           list-style: none;
           padding:0;
           margin:0;
           margin-left: 60px;
           @media #{$underdesktop} {
             margin-left: 40px;
           }
        }

        li {
           padding-left: 1em;
           @media #{$desktop-xl} {
             display: inline-grid;
           }
           @media #{$under-desktop-xl} {
             display: inline-grid;
           }
           @media #{$underdesktop} {
             display: inline-grid;
           }
        }
        li::before {
          content: "•";
          color: $orange;
          display: inline-block;
          width: 0.5em;
          margin-left: -1em;
          font-size: 50px;
          line-height: 10px;
          left: 0px;
          position: relative;
          right: -50px;
          top: 7px;
          @media #{$underdesktop} {
            margin-left: -0.5em;
          }
        }

        .paragrafo{
          margin-left: 40px;
        }
      }
    }
    .mappa{
      margin-left: -15px;

      iframe{
        @media #{$undermd} {
          height: 400px !important;
        }
      }
    }

  }
}
