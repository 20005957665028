.slick-slide{
  background: transparent;
  padding: 5px;
  img{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -10;
    -moz-border-radius: 0;
    border-radius: 0;

  }
}

.gallery{
  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: $black;
    border: 0;
    background: none;
    z-index: 1;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-prev:before {
    font-family: FontAwesome;
    content: "\f053";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    color: $black;
    font-size: 30px;
    @media #{$undermd} {
      font-size: 25px;
    }
  }

  .slick-next {
      right: -30px;
      text-align: right;
  }

  .slick-next:before {
    font-family: FontAwesome;
    content: "\f054";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    color: $black;
    font-size: 30px;
    @media #{$undermd} {
      font-size: 25px;
    }
  }

  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: $orange;
    font-size: 30px;
    @media #{$undermd} {
      font-size: 25px;
    }
  }

  .slick-dots li {
    @media #{$desktop-xl} {
      margin: 0px;
    }
    @media #{$underdesktop} {
      margin: 0px;
    }
    @media #{$undermd} {
      width: 10px;
      height: 10px;
    }
    @media #{$small-phone} {
      width: 10px;
      height: 10px;
    }

  }
}
