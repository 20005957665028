/******* Menu *******/
.navbar-wrapper {
  background-color: transparent;
  position: relative;
  color: $white;
  z-index: 999 !important;
  transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  @media #{$undermd} {
    padding: 0px 40px;
  }
}

.sticky-wrapper{
  @media #{$undermd} {
    height: 70px !important;
  }
}

.is-sticky{
  .navbar-wrapper {
    background-color: $grigioScuro;
    position: relative;
    color: $white;
    z-index: 999 !important;
    transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
  }
}

.navbar{
  margin-bottom: 0px !important;

  .container{
    height: 65px;
    border-bottom: 1px solid $white;
  }
}

.navbar-brand {
  float: left;
  height: auto;
  padding: 10px 0;
  font-size: 18px;
  line-height: 20px;
  background: $white;
  @media #{$underdesktop} {
    padding: 5px 0;
  }
}

.navbar-nav>li {
  @media #{$undermd} {
    float: none;
  }
}

.navbar>.container-fluid .navbar-brand, .navbar>.container .navbar-brand, .navbar .navbar-brand {
  margin-left: 0px !important;
  height: 90px;
  margin-bottom: 0px !important;
  background: transparent ;
  padding: 0px 10px;
  width: 280px;
  height: 0;
  border-bottom: 80px solid $white;
  border-right: 30px solid transparent;
  position: relative;
  top:-1px;
  left: -15px;
  position: relative;
  @media #{$underdesktop} {
    border-bottom: 75px solid $white;
    width: 250px;
  }
  @media #{$undermd} {
    border-bottom: 65px solid $white;
    width: 220px;
  }
}

.navbar-brand{
  background: $white;
  img{
    width: 230px;
    @media #{$underdesktop} {
      width: 200px;
    }
    @media #{$undermd} {
      width: 180px;
    }

  }
}

.navbar-right {
  float: right!important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.navbar-collapse{
  margin: 0 30px;
}

.navbar {
  font-family: 'Montserrat', serif;
  position: relative;
  border-radius: 0;
  height: 80px;
  font-size: 16px;
  @media #{$undermd} {
    margin: 0 10px;
    min-height: 80px;
  }
  @media #{$small-phone} {
    margin: 0 10px;
    min-height: 80px;
  }
  @media #{$undermd} {
    min-height: 60px;
    height: 60px;
  }
}

.menu {
  position: relative;
  z-index: 9;
  text-align: center;
  display: inline;

  li {
    text-align: center;
    padding: 0;
    display: inline-block;
    margin: 0 5px;

  }

  li a,  li span {
    color: $white;
    text-decoration: none;
    font-size: 16px;
    padding: 0 10px 0 15px;
    line-height: 35px;
    margin: 5px 0;
    font-family: 'Montserrat', serif;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom:1px dashed transparent;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    @media #{$underdesktop} {
      padding: 0 5px 0 5px;
      font-size: 14px;
    }
  }

  li.active > a, li.active > a:focus, .nav > li.active > a:hover {
      background-color: transparent !important;
      color: $orange !important;
      text-decoration: none !important;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
  }

  li a:hover, li a:focus {
    color: $orange;
    background-color: transparent;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }

  li a:before {
    background: transparent;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -8px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }

  li a:hover:before, li.active a:before {
    background: url(../images/frecce_dx.png) no-repeat;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -4px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }



  li a:active {
      background-color: transparent;
  }
}

.social{
  display: inline;
  position: relative;

  .menu-social{
    margin-left: 30px;
  }

  li a{
    padding: 2px;
    margin-top: 10px;
    background: transparent;
  }
  li a i, li span i {
      border: 1px solid $white;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      font-size: 20px;
      height: 30px;
      letter-spacing: -.1em;
      line-height: 30px;
      margin-right: 5px;
      margin-top: -10px;
      text-align: center;
      width: 30px;
      color: #000;
      background: #908475;
      border-bottom: none;
  }
  li a i:hover {
    display: inline-block;
    font-size: 20px;
    height: 30px;
    letter-spacing: -.1em;
    line-height: 30px;
    margin-right: 5px;
    margin-top: -10px;
    text-align: center;
    width: 30px;
    color: black;
    background: $white;
  }

  li a:hover{
    background: transparent;
    border-bottom: none;
  }

  li a:hover:before,li a:focus, li.active a:before {
    background: none;
    content: "";
  }
}

.dropdown{ display:block !important; }

.menu .menunav ul {
    display: none;
    padding: 5px 0;
    position: absolute;
    top: 50px;
    width: 200px;
    z-index: 9999;
}

.menu li:hover ul {
    display: block;
}

/*** Menu responsive Overlay ***/
#navigation-toggle span.nav-line {
    display: block;
    height: 3px;
    width: 20px;
    margin-bottom: 4px;
    background: #c7c7c7!important;
}

button#navigation-toggle {
  background-color: transparent !important;
  display: none;
  padding: 10px;
  display: inline-block;
  position: relative;
  float: right;
  transform: translateY(50%);

  @media #{$undermd} {
    display: block;
  }

  span{
    background: $white;
    color: $white;
  }
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: $black;
  opacity: 0.98;

  ul li a:before {
    background: transparent;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -20px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }

  ul li a:hover:before {
    background: url(../images/frecce_dx.png) no-repeat;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -4px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 80px;
	height: 80px;
	position: absolute;
	right: 20px;
	top: 20px;
	overflow: hidden;
	border: none;
	background: url(../images/close.png) no-repeat center center;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 100;
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 60%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: auto;
  position: relative;
  width: 100%;
  top: 50%;
  @include transform(translateY(-50%));
}

.overlay ul li {
	display: block;
	height: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  margin: 20px 0;
}

.overlay ul li a {
	font-size: 30px;
	font-weight: 300;
  display: inline-block;
	color: $white;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
  border-bottom: 1px dashed transparent;
}

.overlay ul li a:hover,
.overlay ul li a:focus {
	color: $orange;
}

/* Effects */
.overlay-slidedown {
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: -webkit-transform 0.4s ease-in-out, visibility 0s 0.4s;
	transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
}

.overlay-slidedown.open {
	visibility: visible;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	-webkit-transition: -webkit-transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}

//Breadcrumbs
#breadcrumbs{
  @media #{$undermd} {
    padding: 0px 40px;
  }
}
#crumbs{
  padding: 5px 0 5px 2px;
  a{
    color: $white;
    text-decoration: none;
    font-weight: 200;
    font-size: 16px;
  }

  a:hover{
    color: $white;
    text-decoration: none;
  }

  span{
    font-size: 16px;
    color: $white;
  }
}
