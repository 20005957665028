body.login{
  background-color: $grey;
}

.login h1 a {
  background-image: url('../images/logo_bernio.png');
  background-size: 300px;
  background-position: center top;
  background-repeat: no-repeat;
  color: #444;
  height: 100px;
  font-size: 20px;
  line-height: 1.3em;
  margin: 0 auto 0px;
  padding: 0;
  width: 350px;
  text-indent: -9999px;
  outline: 0;
  display: block;
}

.login label {
  font-size: 14px;
  color: #555555;
}

.login input[type="text"]{
  background-color: #ffffff;
  border-color:#dddddd;
  -webkit-border-radius: 4px;
  margin: 0;
}

.login input[type="password"]{
  background-color: #ffffff;
  border-color:#dddddd;
  -webkit-border-radius: 4px;
}

.login .button-primary {
  width: 120px;
  float:right;
  background-color:#17a8e3 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#17a8e3), to(#17a8e3));
  background: -webkit-linear-gradient(top, #17a8e3, #17a8e3);
  background: -moz-linear-gradient(top, #17a8e3, #17a8e3);
  background: -ms-linear-gradient(top, #17a8e3, #17a8e3);
  background: -o-linear-gradient(top, #17a8e3, #17a8e3);
  background-image: -ms-linear-gradient(top, #17a8e3 0%, #17a8e3 100%);
  color: #ffffff;
  -webkit-border-radius: 4px;
  border: 1px solid #0d9ed9;
}

.login .button-primary:hover {
  background-color:#17a8e3 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#17a8e3), to(#0d9ed9 ));
  background: -webkit-linear-gradient(top, #17a8e3, #0d9ed9 );
  background: -moz-linear-gradient(top, #17a8e3, #0d9ed9 );
  background: -ms-linear-gradient(top, #17a8e3, #0d9ed9 );
  background: -o-linear-gradient(top, #17a8e3, #0d9ed9 );
  background-image: -ms-linear-gradient(top, #0b436e 0%, #0d9ed9 100%);
  color: #fff;
  -webkit-border-radius: 4px;
  border: 1px solid #0d9ed9;
}

.login .button-primary:active {
  background-color:#17a8e3 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#0d9ed9), to(#17a8e3));
  background: -webkit-linear-gradient(top, #0d9ed9, #17a8e3);
  background: -moz-linear-gradient(top, #0d9ed9, #17a8e3);
  background: -ms-linear-gradient(top, #0d9ed9, #17a8e3);
  background: -o-linear-gradient(top, #0d9ed9, #17a8e3);
  background-image: -ms-linear-gradient(top, #0d9ed9 0%, #17a8e3 100%);
  color: #fff;
  -webkit-border-radius: 4px;
  border: 1px solid #0d9ed9;
}

.login #backtoblog a, .login #nav a {
    text-decoration: none;
    color: $black;
}
.login #backtoblog a:hover, .login #nav a:hover {
    text-decoration: none;
    color: $orange;
}

.login form {
    margin-top: 20px;
    margin-left: 0;
    padding: 26px 24px 46px;
    background: $white;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.13);
    box-shadow: 0 1px 3px rgba(0,0,0,.13);
}
p#nav {
  display: none;
}
