#lavorazioni-meccaniche{
  // figure{
  //   figcaption{
  //     .vertical_line_orange{
  //       @media #{$under-desktop-xl} {
  //         height: 140%;
  //       }
  //       @media #{$undermd} {
  //         height: 160%;
  //       }
  //       @media #{$undertablet} {
  //         height: 80%;
  //       }
  //
  //
  //       @media #{$small-phone} {
  //         height: 100%;
  //       }
  //       @media #{$xsmall-phone} {
  //         height: 100%;
  //       }
  //     }
  //   }
  // }

  #elenco-lavorazioni{
    margin: 60px 30px;
  }

  .cta-page{
    .titolo{
      color: $white;
      font-size: 40px;
      margin-top: 20px;
      text-align: left;
      top:50%;
      position: relative;
      @include transform(translateY(-50%));
      @media #{$undermd} {
        margin-top: 0px;
      }
      @media #{$undertablet} {
        text-align: center;
      }
    }

    .descrizione{
      color: $white;
    }
  }
}

#paragrafi{
  margin: 60px 0;
  @media #{$undermd} {
    margin: 0px 0;
  }
  .boxParagrafi.even{
    .immagine{
      .img-bordered{
        border-left: 0px;
        border-right: 10px solid $orange;
        margin-left: 40px;
        position: relative;
        z-index: 1;
        @media #{$underxl} {
          margin-left: 0px;
        }
      }
    }
    .contenuti{
      padding-right: 60px;
      @media #{$underxl} {
        padding-left: 80px;
      }
      @media #{$underdesktop} {
        padding-left: 20px;
        padding-right: 40px;
      }
      .title{
        padding-right: 60px;
        margin-bottom: 60px;
        @media #{$undermd} {
          padding-right: 0px;
        }
      }
      .title:after{
        content: "";
        position: absolute;
        width: 140%;
        height: 5px;
        background: $orange;
        right: 0;
        margin-top: 70px;
      }

      .cta-button{
        padding: 5px 20px;
        border: 3px solid $orange;
        color: $orange;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 20px;
        text-decoration: none;
        position: relative;
        top:20px;
      }

      .cta{
        font-size: 20px;
      }
    }
  }

  .boxParagrafi.odd{
    margin-top: 100px;

    .immagine{
      .img-bordered{
        border-left: 10px solid $orange;
        margin-left: 40px;
        position: relative;
        z-index: 1;
        @media #{$desktop-xl} {
          padding-right: 50px;
        }
        @media #{$underdesktop} {
          margin-left: 0px;
        }
      }
    }
    .contenuti{
      padding-left: 60px;
      @media #{$underdesktop} {
        padding-left: 20px;
      }
      .title{
        padding-right: 60px;
        margin-bottom: 60px;

      }
      .title:after{
        content: "";
        position: absolute;
        width: 140%;
        height: 5px;
        background: $orange;
        left: 0;
        margin-top: 70px;
      }
      .cta-button{
        padding: 5px 20px;
        border: 3px solid $orange;
        color: $orange;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 20px;
        text-decoration: none;
        position: relative;
        top:20px;
      }
      .cta{
        font-size: 20px;
      }
    }
  }

  .boxParagrafo{
    .immagine{
      .img-bordered{
        border-left: 0px;
        border-right: 10px solid $orange;
        margin-left: 40px;
        position: relative;
        z-index: 1;
        @media #{$underxl} {
          margin-left: 0px;
        }
        @media #{$undermd} {
          border-right: 0px solid $orange;
          border-left: 10px solid $orange;
        }
      }
    }
    .title{

      @media #{$undermd} {
        margin-top: 10px;
      }
      @media #{$undertablet} {
        text-align: center;
      }
    }
    .contenuti{
      padding-right: 60px;
      @media #{$underxl} {
        padding-left: 80px;
      }
      @media #{$underdesktop} {
        padding-left: 20px;
        padding-right: 40px;
      }
      @media #{$undertablet} {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
      }
      .title{
        padding-right: 60px;
        margin-bottom: 60px;
        @media #{$undermd} {
          padding-right: 0px;
        }
        @media #{$undermd} {
          margin-top: 20px;
        }
      }
      .title:after{
        content: "";
        position: absolute;
        width: 140%;
        height: 5px;
        background: $orange;
        right: 0;
        margin-top: 70px;
      }



      .cta-button{
        padding: 5px 20px;
        border: 3px solid $orange;
        color: $orange;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 20px;
        text-decoration: none;
        position: relative;
        top:20px;
        @media #{$undermd} {
          top:0;
          margin-bottom: 40px;
          display: inline-block;
        }
      }

      .cta{
        font-size: 20px;
      }
    }
  }
}


#cta-tornitura, #cta-fresatura, #cta-tornio-fresa, #cta-complementari{
  .titolo {
    margin-top: 0px;
    margin-right: 200px;
    @media #{$undertablet} {
      margin-right: 0px;
    }
  }
  .button-cta{
    bottom:0;
    top:auto;
    margin-bottom: 0;
  }
}


#paragrafi-accordion{
  margin: 60px 0;

  #accordion{

    cursor: pointer;
    .accordion-section{
      margin-bottom: 10px;
    }
    .accordion-section-title{
      position: relative;
      .immagine{
        margin-left: 0;
        transition: all 0.6s ease;
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;

        img{
          width: 100%;
          z-index: -1;
          position: relative;
        }
      }

      .img-sel{
        background: rgba(227,138,62,.8);
        transition: all 0.6s ease;
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
      }

      .titolo-accordion{
        position: absolute;
        bottom: 0px;
        padding-left: 60px;
        min-width: 900px;

        background: rgba(227,138,62,.8);
        padding: 10px 10px 10px 40px;
        @media #{$small-phone} {
          padding: 5px 5px 5px 20px;
        }

        .title{
          margin-bottom: 0;
          color: $white;
          @media #{$undermd} {
            font-size: 30px;
          }
          @media #{$undertablet} {
            font-size: 25px;
            margin-top: 0;
          }
          @media #{$small-phone} {
            font-size: 20px;
          }
          @media #{$xsmall-phone} {
            font-size: 14px;
          }
        }
      }

      .titolo-accordion:after{
        content:'';
         position: absolute;
         top: 0;
         left: 100%;
         width: 0;
         height: 0;
         border-top: solid 64px transparent;
         border-left: solid 40px rgba(227,138,62,.8);
         border-right: solid 50px transparent;
         @media #{$desktop-xl} {
           border-top: solid 64px transparent;
         }
         @media #{$under-desktop-xl} {
           border-top: solid 64px transparent;
         }

         @media #{$tablet} {
           border-top: solid 53px transparent;
         }
         @media #{$undertablet} {
           border-top: solid 47px transparent;
         }
         @media #{$small-phone} {
           border-top: solid 32px transparent;
           border-left: solid 25px rgba(227,138,62,.8);
           border-right: solid 50px transparent;
         }
         @media #{$xsmall-phone} {
           border-top: solid 25px transparent;
           border-left: solid 20px rgba(227,138,62,.8);
           border-right: solid 50px transparent;
         }
      }

      i{
        background: $orange;
        padding: 10px;
        font-size: 14px;
        display: inline;
        position: relative;
        top: -10px;
        margin-left: 10px;
      }

    }

    .accordion-section-content{
      .contenuti{
        padding: 30px;
        .paragrafo{
          border-left: 10px solid $orange;
          padding-left: 20px;
        }
      }
    }
  }


}
