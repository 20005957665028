#servizi{
  figure{
    figcaption{
      .vertical_line_orange{
        @media #{$xsmall-phone} {
          height: 60%;
        }
      }
    }
  }
  #cta-prodotti{
    text-align: right;
    span{
      color: $orange;
    }

    h3{
      @media #{$undermd} {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }

    .vertical-centered{
      @media #{$underdesktop} {
        top:40%;
      }
    }

    img{
      @media #{$underdesktop} {
        margin-bottom: 40px;
      }
      @media #{$small-phone} {
        width: 80%;
      }
    }


    .testo{
      @media #{$undermd} {
        padding-right: 40px;
      }
      @media #{$undertablet} {
        top:0;
        text-align: center;
        padding-right: 0;
        @include transform(translateY(0%));
      }

    }
  }
}
