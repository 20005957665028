/************ STILE TEMA ************/

h2{
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 25px;
  @media #{$desktop-xl} {
    font-size: 40px;
    margin-bottom: 25px;
  }
  @media #{$under-desktop-xl} {
    font-size: 40px;
  }
  @media #{$underdesktop} {
    font-size: 40px;
  }
  @media #{$small-phone} {
    font-size: 30px;
  }
}

h3{
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 25px;
  @media #{$desktop-xl} {
    font-size: 40px;
  }
  @media #{$under-desktop-xl} {
    font-size: 40px;
  }
  @media #{$underdesktop} {
    font-size: 40px;
  }
  @media #{$undermd} {
    margin-bottom: 25px;
    margin-top: 0px;
  }
  @media #{$undertablet} {
    margin-top: 20px;
  }
  @media #{$small-phone} {
    font-size: 30px;
  }

}

h4{
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  @media #{$undermd} {
    font-size: 22px;
  }
}


//sezioni
.margin-section{
  margin: 60px 0;
  @media #{$desktop-xl} {
    margin: 40px 0;
  }
  @media #{$underdesktop} {
    margin: 20px 0 60px 0;
  }
}
.margin-section-top{
  margin: 20px 0 40px;
  @media #{$desktop-xl} {
    margin: 40px 0;
  }
  @media #{$underdesktop} {
    margin: 20px 0 60px 0;
  }
}

//immagini
.img-center{
  margin: 0 auto;
}

.img-bordered{
  border-left: 10px solid $orange;
  margin-left: 40px;

  @media #{$undermd} {
    margin-left: 0px;
    margin-bottom: 20px;
  }

}

.vertical-centered{
  position: relative;
  top:50%;
  @include transform(translateY(-50%));
  @media #{$undertablet} {
    top:0%;
    @include transform(translateY(0%));
  }
}

//titoli
.titolo{
  text-transform: uppercase;
  text-align: center;
  span{
    color: $orange;
  }

}

.title_black{
  padding-right: 60px;
  margin-bottom: 60px;
}

.title_black:after{
  content: "";
  position: absolute;
  width: 140%;
  height: 5px;
  background: $orange;
  left: 0;
  margin-top: 70px;
}

//box
.box{
  padding: 40px;
  @media #{$underdesktop} {
    padding: 5px;
  }
  @media #{$undermd} {
    padding: 0px;
  }
}




//figure-figcaption
figure.img-sfondo {
  position: relative;
  width: 100%;
  height: 100vh;
  @media #{$undertablet} {
    height: 135vh;
  }
  @media #{$small-phone} {
    height: 100%;
  }

  img.img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -10;
    opacity: 1;

  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      img.img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }

   @supports (-ms-ime-align:auto) {
     /* IE Edge 12+ CSS styles go here */
      img.img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }

   .cover{
     height: 100vh;
     @media #{$undertablet} {
       height: 135vh;
     }
     @media #{$small-phone} {
       display: none;
     }

     img {
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       object-fit: cover;
       width: 100%;
       height: 100%;
       z-index: -10;
       opacity: 1;

     }

     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         /* IE10+ CSS styles go here */
         img {
           height: inherit;
           width: inherit;
           position: absolute;
           top: -9999px;
           right: -9999px;
           bottom: -9999px;
           left: -9999px;
           margin: auto;
           min-width: 100%;
           min-height: 100%;
           z-index: -10;
         }
      }

      @supports (-ms-ime-align:auto) {
        /* IE Edge 12+ CSS styles go here */
         img {
           height: inherit;
           width: inherit;
           position: absolute;
           top: -9999px;
           right: -9999px;
           bottom: -9999px;
           left: -9999px;
           margin: auto;
           min-width: 100%;
           min-height: 100%;
           z-index: -10;
         }
      }
   }

  figcaption {
    position: absolute;
    top: 50%;
    @include transform(translate(-50%,-50%));
    color: $white;
    left: 50%;
    text-align: center;
    @media #{$undertablet} {
      top:0%;
      @include transform(translateY(0%));
      @include transform(translateX(-50%));
      width: 100%;
      padding: 40px;
    }
    @media #{$small-phone} {
      position: relative;
      background: $black;
      margin-bottom: 20px;
      display: block;
      top:0%;
      @include transform(translateY(0%));
      @include transform(translateX(-50%));
      padding-bottom: 80px;
    }
  }
}

.icona{
  width: 180px;
  margin: 0 auto;


  img{
    width: 180px;
    min-width: 180px !important;
    position: relative !important;
  }
}

//button
.button-orange{
  background: $orange;
  padding: 10px 30px;
  color: $white;
  text-decoration: none;
  @media #{$underdesktop} {
    top:20px;
    position: relative;
  }
}

.button-cta{
  background: transparent;
  padding: 10px 40px 10px 30px;
  color: $white;
  border:2px solid $white;
  text-decoration: none;
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 20px;
  @media #{$undermd} {
    position: relative;
    top:10px;
    display: inline-block;
    padding: 5px 30px 5px 20px;
  }
}
.button-cta:hover, .button-cta:focus{
  background: transparent;
  padding: 10px 40px 10px 30px;
  color: $orange;
  border:2px solid $orange;
  text-decoration: none;
  @media #{$undermd} {
    padding: 5px 30px 5px 20px;
  }
  @media #{$undertablet} {
    padding: 5px 30px 5px 20px;
  }
}

.button-cta:after{
  content:"\f178";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size: 18px;
  position: relative;
  top: 0px;
  right: -15px;

}


.box-category{
  figure {
    border-bottom: 4px solid $orange;
    z-index: 1;
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    @media #{$undermd} {
      margin: 20px !important;
    }

    img{
      width: 100%;
    }

    figcaption{
      width: 100%;
      bottom: 0;
      position: absolute;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      overflow: hidden;

      .triangolo{
        background: url("../images/triangolo.svg") no-repeat;
        width: 102%;
        height: 90px;
        /* background-size: 100% 100%; */
        display: block;
        background-position: center bottom;
        position: relative;
        bottom: 0px;
        top:2px;
        left: -2px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
           top:20px;
         }

         @supports (-ms-ime-align:auto) {
           top:20px;
         }

        @media #{$undermd} {
          top:2px;
        }
      }

      .elementi{
        padding-top: 20px;
        background: $white;
        text-align: center;
        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        -o-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
      }

      .titolo{
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        position: relative;
            top: -20px;
        @media #{$underxl} {
          padding-top: 10px;
        }
        @media #{$undermd} {
          padding-top: 0px;
        }
      }
      p{
        line-height: 20px;
      }

      h3 {
      	position: relative;
      	left: 0;
      	width: 100%;
      	color: $black;
        font-weight: 800;
        margin: 0;
        font-size: 25px;
        text-transform: uppercase;
        -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
        transition: opacity 0.5s, transform 0.5s;
        @media #{$underdesktop} {
          font-size: 22px;
        }
      }

      .descrizione {
        opacity: 0;
        -webkit-transition: opacity .0s ease-in-out;
        -moz-transition: opacity .0s ease-in-out;
        -ms-transition: opacity .0s ease-in-out;
        -o-transition: opacity .0s ease-in-out;
        transition: opacity .0s ease-in-out;
        -webkit-transform: translate3d(0,0px,0);
        transform: translate3d(0,0px,0);
        color: $black;
        bottom: -40px;
        padding: 0px 30px;
        top:30px;
        text-align: center;
        height: 0;
      }

      .link{
        height: 0;
        opacity: 0;
        background: $white;
        position: relative;
        color: $orange;
        height: auto;
        border:3px solid $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        opacity: 0;
        border:2px solid $orange;
        padding: 5px 20px;
        @include border-radius(0);
        margin: 15px 0;
        -webkit-transition: opacity .0s ease-in-out;
        -moz-transition: opacity .0s ease-in-out;
        -ms-transition: opacity .0s ease-in-out;
        -o-transition: opacity .0s ease-in-out;
        transition: opacity .0s ease-in-out;
      }
    }
  }

  figure:hover {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    overflow: hidden;
    figcaption{
      position: absolute;
      height: auto;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }

    h3 {
    	color:$black;
      margin: 0;
    	-webkit-transform: translate3d(0,0px,0) translate3d(0,0px,0);
    	transform: translate3d(0,0px,0) translate3d(0,0px,0);
      -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
      transition: opacity 0.5s, transform 0.5s;
    }
    .triangolo{
      background: url("../images/triangolo_hover.svg") no-repeat;
      width: 102%;
      height: 90px;
      /* background-size: 100% 100%; */
      display: block;
      background-position: center bottom;
      bottom: 0px;
      position: relative;
      bottom: 0px;
      top:2px;
      left: -2px;
      @media #{$undermd} {
        top:2px;
      }
    }
    .elementi{
      padding: 20px;
      background: $white;
      -webkit-transition: all 0.1s ease-in;
      -moz-transition: all 0.1s ease-in;
      -o-transition: all 0.1s ease-in;
      transition: all 0.1s ease-in;
    }

    .descrizione {
      opacity: 1;
      display: block;
      height: 100%;
      -webkit-transition: opacity .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out;
      -ms-transition: opacity .5s ease-in-out;
      -o-transition: opacity .5s ease-in-out;
      transition: opacity .5s ease-in-out;
      -webkit-transform: translate3d(0,0px,0);
      transform: translate3d(0,0px,0);
      color: $black;
      bottom: -40px;
      padding: 0px 30px;
      top:30px;
      text-align: center;
      @media #{$underxl} {
        top: 40px;
      }
      @media #{$under-desktop-xl} {
        top:50px;
      }
      @media #{$underdesktop} {
        top:50px;
      }
    }

    .link{
      display: inline-block;
      background: $white;
      position: relative;
      color: $orange;
      height: auto;
      border:3px solid $white;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      opacity: 1;
      border:2px solid $orange;
      padding: 5px 20px;
      @include border-radius(0);
      margin: 15px 0;
      -webkit-transition: opacity .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out;
      -ms-transition: opacity .5s ease-in-out;
      -o-transition: opacity .5s ease-in-out;
      transition: opacity .5s ease-in-out;
    }

  }

  figure::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 1;
    // background: -webkit-linear-gradient(top, rgba(19,19,19,0) 0%, rgba(19,19,19,0.9) 100%);
    // background: linear-gradient(to bottom, rgba(19,19,19,0) 0%, rgba(19,19,19,0.9) 100%);
    //background: transparent;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

  }

  figure:hover::before {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
    content: '';
  	opacity: 1;
  	// background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
  	// background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
    background: transparent;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
  }
}

.cta-button-arrow {
    padding: 5px 40px 5px 20px;
    border: 3px solid $orange;
    color: $orange;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;

    text-decoration: none;
    position: relative;
    top: 20px;
    @media #{$underdesktop} {
      margin-top: 0px;
      display: block;
      position: relative;
    }

    @media #{$undermd} {
      display: inline-block;
      top:0;
      padding: 5px 30px 5px 20px;
      margin-top: 0;
      margin-bottom: 30px;
    }

}
.cta-button-arrow:after {
  content:"\f178";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size: 18px;
  position: relative;
  top: 0px;
  right: -15px;
}

.cta-button-arrow:hover {
    padding: 5px 40px 5px 20px;
    border: 3px solid $black;
    color: $black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 20px;
    text-decoration: none;
    position: relative;
    top: 20px;
    @media #{$undermd} {
      display: inline-block;
      top:0;
      padding: 5px 30px 5px 20px;
      margin-top: 0;
      margin-bottom: 30px;
    }
}
