#prodotti{


  #image{
    .img-header{
      text-align: center;
      top: -240px;
      position: relative;
      margin-bottom: -180px;
      z-index: 1;
      @media #{$under-super-desktop} {
        top: -260px;
        margin-bottom: -240px;
      }
      @media #{$desktop-xl} {
        top: -260px;
        margin-bottom: -240px;
      }
      @media #{$under-desktop-xl} {
        top: -200px;
        margin-bottom: -160px;
      }
      @media #{$underdesktop} {
        top: -200px;
        margin-bottom: -160px;
      }
      @media #{$desktop} {
        top: -220px;
        margin-bottom: -180px;
      }
      @media #{$undermd} {
        top: -240px;
        margin-bottom: -180px;
      }
      @media #{$undertablet} {
        top: -240px;
        margin-bottom: -180px;
      }
      @media #{$small-phone} {
        top: -200px;
        margin-bottom: -200px;
      }



      img{
        @media #{$underdesktop} {
          width: 70%;
        }
      }
    }
    // .vertical_line_orange{
    //   width: 3px;
    //   height: 250px;
    //   background: $orange;
    //   position: absolute;
    //   bottom: 5px;
    //   left: 51%;
    //   top: 0;
    //   z-index: -1;
    //   @include transform(translateX(-50%));
    //   @media #{$underdesktop} {
    //     left: 50.3%;
    //     height: 200px;
    //   }
    //   @media #{$undermd} {
    //     left: 50.6%;
    //     height: 120px;
    //   }
    //   @media #{$underdesktop} {
    //     display: none;
    //   }
    //
    //
    // }

  }
  #menu-prodotti{
    background: $orange;
    @media #{$undertablet} {
      padding: 0 60px;
    }

    .elenco-prodotti-menu{
      background: $white;
      position: relative;
      text-align: left;

      .immagine{
        text-align: center;
        img{
          width: 150px;
          margin: 0 auto;
          @media #{$underdesktop} {
            width: 100px;
          }
        }

      }
      a{
        text-decoration: none;
      }
      a:hover{
        color: $orange;
      }

      .prodotto{
        .titolo{
          font-weight: 800;
          @media #{$underdesktop} {
            font-size: 14px;
          }
          @media #{$undermd} {
            font-size: 11px;
          }

        }
      }

    }

    .elenco-prodotti-menu:before{
      border-top: 194px solid $white;
      border-left: 90px solid $orange;
      content: "";
      position: absolute;
      @media #{$underdesktop} {
        border-top: 140px solid $white;
        border-left: 50px solid $orange;
      }
      @media #{$undermd} {
        border-top: 125px solid $white;
        border-left: 50px solid $orange;
      }
      @media #{$undertablet} {
        border-top: 0px solid $white;
        border-left: 0px solid $orange;
      }
    }
    .elenco-prodotti-menu:after{
      border-bottom: 195px solid $white;
      border-right: 90px solid $orange;
      content: "";
      position: absolute;
      right: 0;
      top:0;
      @media #{$underdesktop} {
        border-bottom: 140px solid $white;
        border-right: 50px solid $orange;
      }
      @media #{$undermd} {
        border-bottom: 125px solid $white;
        border-right: 50px solid $orange;
      }
      @media #{$undertablet} {
        border-top: 0px solid $white;
        border-right: 0px solid $orange;
      }
    }
  }

  #prodotti-elenco{
    .cta-filettatura{
      text-align: center;
      padding: 40px 0;
    }
    .boxProdotto.even{
      position: relative;
      background: $grey;
      padding: 40px;

      .immagine{
        padding-right: 60px;
        img{
          @media #{$undermd} {
            top: 50%;
            @include transform(translateY(-50%));
            position: relative;
          }
        }
      }

      .contenuti{
        position: relative;
        text-align: right;
        .img-icona{
          width: 100px;
          position: relative;
          right: 0;
          display: inline-block;
        }

        h3{
          position: relative;
        }

        h3:before{
          content: "";
          width: 90%;
          background: $orange;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 0;
        }
      }
    }
    .boxProdotto.odd{
      position: relative;
      background: $white;
      padding: 40px;

      .immagine{
        padding-left: 60px;
        @media #{$undertablet} {
          padding-left: 0px;
          margin-bottom: 40px;
          padding-right: 0;
        }
        img{
          @media #{$undermd} {
            top: 50%;
            @include transform(translateY(-50%));
            position: relative;
          }
          @media #{$undertablet} {
            top: 0%;
            @include transform(translateY(0%));
            position: relative;
          }
        }
      }

      .contenuti{
        text-align: left;
        position: relative;
        @media #{$undertablet} {
          text-align: center;
        }
        .img-icona{
          width: 100px;
          position: relative;
          left: 0;
          display: inline-block;
        }
        h3{
          position: relative;
        }

        h3:before{
          content: "";
          width: 90%;
          background: $orange;
          height: 5px;
          position: absolute;
          bottom: -10px;
          @media #{$undertablet} {
            left: 50%;
            @include transform(translateX(-50%));
          }

        }
      }
    }

    .boxProdotti.even{
      position: relative;
      background: $grey;
      padding: 40px;

      .immagine{
        padding-right: 60px;
        @media #{$undertablet} {
          padding-left: 0px;
          margin-bottom: 40px;
          padding-right: 0;
        }
        img{
          @media #{$undermd} {
            top: 50%;
            @include transform(translateY(-50%));
            position: relative;
          }
          @media #{$undertablet} {
            top:0%;
            @include transform(translateY(0%));
            position: relative;
          }
        }
      }

      .contenuti{
        position: relative;
        text-align: right;
        @media #{$undertablet} {
          text-align: center;
        }
        .img-icona{
          width: 100px;
          position: relative;
          right: 0;
          display: inline-block;
        }

        h3{
          position: relative;
        }

        h3:before{
          content: "";
          width: 90%;
          background: $orange;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 0;
          @media #{$undertablet} {
            left: 50%;
            @include transform(translateX(-50%));
          }
        }
      }
    }
    .boxProdotti.odd{
      position: relative;
      background: $white;
      padding: 40px;

      .immagine{
        padding-left: 60px;
        @media #{$undertablet} {
          padding-left: 0px;
          margin-bottom: 40px;
          padding-right: 0;
        }
        img{
          @media #{$undermd} {
            top: 50%;
            @include transform(translateY(-50%));
            position: relative;
          }
          @media #{$undertablet} {
            top: 0%;
            @include transform(translateY(0%));
            position: relative;
          }
        }
      }

      .contenuti{
        text-align: left;
        position: relative;
        @media #{$undertablet} {
          text-align: center;
        }
        .img-icona{
          width: 100px;
          position: relative;
          left: 0;
          display: inline-block;
        }
        h3{
          position: relative;
        }

        h3:before{
          content: "";
          width: 90%;
          background: $orange;
          height: 5px;
          position: absolute;
          bottom: -10px;
          @media #{$undertablet} {
            left: 50%;
            @include transform(translateX(-50%));
          }

        }
      }
    }
  }

  #paragrafo_bottom{
    text-align: center;
    span{
      color: $orange;
    }
  }


}
